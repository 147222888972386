import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';


@Injectable({
  providedIn: 'root'
})
export class AirExportQuoteService {

  getZip = URLS.cargoZips;

  constructor(private http: HttpClient) { }

  getZipData(dataParams: any): Observable<{}> {
    return this.http.get(`${this.getZip}`, {
      params: dataParams
    });
  }
}
