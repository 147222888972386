import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class TmsQuoteService {
  getCfs = URLS.cargoCfs;
  getZip = URLS.cargoZips;
  getAir = URLS.cargoAirport;
  tmsGenerate = URLS.tmsgenerate;
  tmsQuoteGenerate = URLS.tmsQuoteGenerate;
  quoteDetail = URLS.quoteDetails;
  priceDetails = URLS.priceDetails;
  timeCutoff = URLS.cutOff;
  getVendor = URLS.vendorList;
  quoteSearchLfs = URLS.tmsQuoteSearchLfs;
  exfreightSearch = URLS.tmsQuoteSearchExfreight;
  shipriteSearch = URLS.tmsQuoteSearchshiprite;

  constructor(private http: HttpClient) { }

  getCfsData(): Observable<{}> {
    return this.http.get(`${this.getCfs}`);
  }

  getVendorData(): Observable<{}> {
    return this.http.get(`${this.getVendor}`);
  }

  getZipData(dataParams: any): Observable<{}> {
    return this.http.get(`${this.getZip}`, {
      params: dataParams
    });
  }

  getCutoff(): Observable<{}> {
    return this.http.get(`${this.timeCutoff}`);
  }
  searchCfsData(dataParams: any): Observable<{}> {
    return this.http.get(`${this.getCfs}`, {
      params: dataParams
    });
  }

  getAirData(): Observable<{}> {
    return this.http.get(`${this.getAir}`);
  }

  searchAirData(dataParams: any): Observable<{}> {
    return this.http.get(`${this.getAir}`, {
      params: dataParams
    });
  }
  generateTms(dataParams: any): Observable<{}> {
    return this.http.post(`${this.tmsGenerate}`, dataParams);
  }

  generateTmsQuote(dataParams: any): Observable<{}> {
    return this.http.post(`${this.tmsQuoteGenerate}`, dataParams);
  }

  getQuoteDetails(dataParams: any): Observable<{}> {
    return this.http.get(`${this.quoteDetail}`, {
      params: dataParams
    });
  }

  getPriceDetails(dataParams: any): Observable<{}> {
    return this.http.get(`${this.priceDetails}`, {
      params: dataParams
    });
  }

  tmsQuoteSearchLfs(dataParams: any): Observable<{}> {
    return this.http.post(`${this.quoteSearchLfs}`, dataParams);
  }

  tmsQuoteSearchExfreight(dataParams: any): Observable<{}> {
    return this.http.post(`${this.exfreightSearch}`, dataParams);
  }

  tmsQuoteSearchshiprite(dataParams: any): Observable<{}> {
    return this.http.post(`${this.shipriteSearch}`, dataParams);
  }
}
