import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class ExportService {
  exportDest = URLS.destinationList;
  exportRegion = URLS.allRegion;
  exportRegionDestination = URLS.regionDestination;
  exportRegionCfs = URLS.regionCFS;
  exportOrigin = URLS.originList;
  quoteHist = URLS.getQuoteHist;
  addQuote = URLS.getQuote;
  addDoorQuote = URLS.getDoorQuote;
  origin = URLS.originByPin;
  thirdAPI = URLS.thirdPartyAPI;
  getDetails = URLS.profileDetails;
  exception = URLS.exceptions;
  editQuoteData = URLS.editQuote;
  rateCard = URLS.getRate;
  getConsolidationCenter = URLS.getConsolidationCenter;
  tmsGenerate = URLS.tmsgenerate;
  householdGoods = URLS.householdGoods;
  getVendor = URLS.vendorList

  getQuoteHist(dataParams: any): Observable<{}> {
    return this.http.get(`${this.quoteHist}`, {
      params: dataParams
    });
  }
  getConsolidate(dataParams: any): Observable<{}> {
    return this.http.post(`${this.getConsolidationCenter}`, dataParams);
  }

  getVendorData(): Observable<{}> {
    return this.http.get(`${this.getVendor}`);
  }


  getRateCard(dataParams: any): Observable<{}> {
    return this.http.post(`${this.rateCard}`, dataParams);
  }

  editQuoteDetail(dataParams: any): Observable<{}> {
    return this.http.put(`${this.editQuoteData}`, dataParams);
  }

  getHouseholdSurcharge(dataParams: any): Observable<{}> {
    return this.http.get(`${this.householdGoods}`, {
      params: dataParams
    });
  }

  getDestination(): Observable<{}> {
    return this.http.get(`${this.exportDest}`);
  }

  getRegion(): Observable<{}> {
    return this.http.get(`${this.exportRegion}`);
  }

  generateTms(dataParams: any): Observable<{}> {
    return this.http.post(`${this.tmsGenerate}`, dataParams);
  }

  getDestinationByRegion(dataParams): Observable<{}> {
    return this.http.get(`${this.exportRegionDestination}`, {
      params: dataParams
    });
  }

  getcfsByRegion(dataParams): Observable<{}> {
    return this.http.get(`${this.exportRegionCfs}`, {
      params: dataParams
    });
  }

  getOrigin(): Observable<{}> {
    return this.http.get(`${this.exportOrigin}`);
  }

  getQuote(dataParams: any): Observable<{}> {
    return this.http.post(`${this.addQuote}`, dataParams);
  }
  getQuoteForDoor(dataParams: any): Observable<{}> {
    return this.http.post(`${this.addDoorQuote}`, dataParams);
  }

  getCFSList(dataParams: any): Observable<{}> {
    return this.http.get(`${this.origin}`, {
      params: dataParams
    });
  }

  getThirdPartyList(dataParams: any): Observable<{}> {
    return this.http.post(`${this.thirdAPI}`, dataParams);
  }

  getOverallCFS(dataParams: any): Observable<{}> {
    return this.http.get(`${this.exportOrigin}`, {
      params: dataParams
    });
  }

  getUserProfileDetails(): Observable<{}> {
    return this.http.get(`${this.getDetails}`);
  }

  getExceptionList(dataParams: any): Observable<{}> {
    return this.http.get(`${this.exception}`, {
      params: dataParams
    });
  }

  constructor(private http: HttpClient) { }
}
