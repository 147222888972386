import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../_config/api.config';
import { Observable } from 'rxjs';

@Injectable()
export class TmsQuoteHistoryService {
    getquoteList = URLS.quoteList;

    constructor(private http: HttpClient) { }

    getQuoteList(param?): Observable<{}> {
        return this.http.get(`${this.getquoteList}`, {
            params: param
        });
    }

    getSearchQuoteList(param): Observable<{}> {
        return this.http.get(`${this.getquoteList}` + param);
    }


}