import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class QuoteHistoryService {
  quoteHist = URLS.getQuoteHist;
  quoteBook = URLS.quoteConfirm;
  getBookVessel = URLS.getVesselBook;
  tmsGenerate = URLS.tmsgenerate;
  quoteSearchLfs = URLS.tmsQuoteSearchLfs;
  exfreightSearch = URLS.tmsQuoteSearchExfreight;
  shipriteSearch = URLS.tmsQuoteSearchshiprite;

  getQuoteHist(dataParams: any): Observable<{}> {
    return this.http.get(`${this.quoteHist}`, {
      params: dataParams
    });
  }

  remindAdmin(dataParams: any): Observable<{}> {
    return this.http.post(URLS.remindAdmin, dataParams);
  }

  bookQuote(dataParams: any): Observable<{}> {
    return this.http.put(`${this.quoteBook}`, dataParams);
  }

  selectTrucker(dataParams: any): Observable<{}> {
    return this.http.put(URLS.quoteUpdate, dataParams);
  }

  getVesselDetails(dataParams: any): Observable<{}> {
    return this.http.get(`${this.getBookVessel}`, {
      params: dataParams
    });
  }

  generateTms(dataParams: any): Observable<{}> {
    return this.http.post(`${this.tmsGenerate}`, dataParams);
  }

  tmsQuoteSearchLfs(dataParams: any): Observable<{}> {
    return this.http.post(`${this.quoteSearchLfs}`, dataParams);
  }

  tmsQuoteSearchExfreight(dataParams: any): Observable<{}> {
    return this.http.post(`${this.exfreightSearch}`, dataParams);
  }

  tmsQuoteSearchshiprite(dataParams: any): Observable<{}> {
    return this.http.post(`${this.shipriteSearch}`, dataParams);
  }

  constructor(private http: HttpClient) { }
}
