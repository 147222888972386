import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class EmailTruckerService {
  exportDest = URLS.destinationList;
  exportOrigin = URLS.originList;
  quoteHist = URLS.getQuoteHist;
  getEmail = URLS.getEmails;
  addEmail = URLS.addTrucker;


  getDestination(): Observable<{}> {
    return this.http.get(`${this.exportDest}`);
  }

  getEmailMaster(): Observable<{}> {
    return this.http.get(`${this.getEmail}`);
  }

  getOverallCFS(dataParams: any): Observable<{}> {
    return this.http.get(`${this.exportOrigin}`, {
        params: dataParams
    });
  }

  addTruckerDetails(dataParams: any): Observable<{}> {
    return this.http.post(`${this.addEmail}`, dataParams);
  }

  getQuoteHist(dataParams : any): Observable<{}> {
    return this.http.get(`${this.quoteHist}`,{
        params : dataParams
      });
  }

  getQuoteDetail(dataParams: any): Observable<{}> {
    return this.http.get(`${URLS.TruckerEmailDetails}`, {
        params: dataParams
    });
  }

  constructor(private http: HttpClient) {}
}
