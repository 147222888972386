import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  get(url: string, params?: any): Observable<any> {
    return this.http.get(`${url}`, { params: params });
  }

  post(url: string, param?: any): Observable<any> {
    return this.http.post(`${url}`, param);
  }

  put(url: string, data?: any): Observable<any> {
    return this.http.put(`${url}`, data);
  }

  patch(url: string, data?: any): Observable<any> {
    return this.http.patch(`${url}`, data);
  }

  delete(url: string, data?: any): Observable<any> {
    return this.http.delete(`${url}`, data);
  }
}
