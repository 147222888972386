import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../_config/api.config';
import { Observable } from 'rxjs';

@Injectable ()
export class PoHistoryService {
  getList = URLS.polist;
  getDetail = URLS.poDetails

  getPolist(dataParams?): Observable<{}> {
    return this.http.get(`${this.getList}`, {
      params: dataParams
  });
  }

  getDetailPo(dataParams): Observable<{}> {
    return this.http.get(`${this.getDetail}`, {
      params: dataParams
  });
  }

  constructor (private http: HttpClient) {}
}
